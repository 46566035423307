<template>
  <vue-scroll class="register-page align-vertical">
    <div class="form-wrapper align-vertical-middle">
      <div class="form-box logout card-base card-shadow--extraLarge flex column gaps">
          <div class="box center">
             <h2 class="accent-text">Log in to your account</h2>
          </div>
          <div class="box">
            <el-form :label-position="labelPasition" :rules="rules" ref="form" label-width="160px" :model="form">
                <el-form-item label="EMAIL" prop="email">
                    <el-input size="medium" v-model="form.email" type="email" name="email" id="frmEmailA" autocomplete="email"></el-input>
                </el-form-item>
                <el-form-item label="PASSWORD" prop="password">
                    <el-input
                      v-on:keyup.enter.native="submit('form')"
                      size="medium"
                      v-model="form.password"
                      type="password"
                      show-password></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="medium" @click="submit('form')" class="signin-btn">
                    LOG IN
                  </el-button>
                </el-form-item>
            </el-form>
          </div>
         <div class="text-center">
            <span class="pr-5">Don't have an account?</span> <el-button type="text" @click="$onCommandParams('register')">Sign in</el-button>
          </div>
          <div class="text-center">
            <el-button type="text" @click="$onCommandParams('forgot')">Forgot Password?</el-button>
          </div>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Login',

  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      rules: {
        email: [
          { required: true, message: 'Please input email', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please input password', trigger: 'blur' },
          { min: 6, message: 'Length should be greater 6', trigger: 'blur' }
        ]
      },
      innerWidth: 0
    }
  },

  computed: {
    labelPasition () {
      return this.innerWidth >= 768 ? 'left' : 'top'
    }
  },

  methods: {
    ...mapActions(['login']),
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login(this.form)
            .then(() => {
              this.$onCommandParams('home')
            })
            .catch(err => {
              this.$message({
                showClose: true,
                message: 'Error ' + err.message,
                type: 'error'
              })
            })
        }
      })
    },
    resizeOpenNav () {
      this.innerWidth = window.innerWidth
    }
  },
  activated () {
    this.$nextTick(() => {
      this.$store.commit('setSplashScreen', false)
    })
  },
  mounted () {
    this.resizeOpenNav()
    window.addEventListener('resize', this.resizeOpenNav)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeOpenNav)
  }
}
</script>
